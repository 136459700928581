.case__heading{
    margin-top: 5.8rem;
    margin-bottom: 3rem;
}
.case__heading .url{
    color:grey;
    text-align: left;
}
.case__heading h2{
    padding-top: 3rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}
.case__heading p{
    text-align: center;
    margin:1rem 0;
    font-size: 1.2rem;
    padding:0 7%;
}

/*--------------------------------------------------------------------------------------------------------------------------*/
.case__afterHeader{
    height:25vw;
}
@media screen and (min-width:1650px) {  
    .case__afterHeader{
        max-width:152rem;
        margin:0 auto;
    }
}
@media screen and (max-width:850px) {  
    .case__afterHeader{
        height: auto;
    }
}
/*--------------------------------------------------------------------------------------------------------------------------*/

.vls {
    margin:2rem auto;
    display: flex;
    width: fit-content;
    padding:0.4rem 1rem;
    color:#423EFE;
    border:1px solid #423EFE;
    /* background-color: blue; */
    border-radius: 2rem;
    margin-bottom: 4rem;
    font-size: 1rem;
    transition: color 0.3s ease;
}
.vls:hover {
    color:white;
    /* border:2px solid #423EFE; */
    background-color: #423EFE;
    /* border-radius: 2rem; */
}

@media screen and (min-width:1650px) {    
    .case__heading h2{
        font-size: 2rem;
    }
    .case__heading p , .vls{
        font-size: 1.2rem;
    }
}
@media screen and (max-width:1024px) {    
    .case__heading h2{
        font-size: 3.2vw;
    }
    .case__heading p {
        font-size: 1.5vw;
    }
    .vls{
        font-size: 1.8vw;
    }
}
@media screen and (max-width:979.8px) { /*mobiles*/
    .case__heading p {
        font-size: 1.2vw;
    }
    .vls{
        font-size: 2.2vw;
    }
}
@media screen and (max-width:979.7px) { 
    .case__heading p { 
        /* font-size: 1.7vw; */
        font-size: 1.7vw;
    }
    .vls{
        font-size: 1.8vw;
    }
}
@media screen and (max-width:825px) {    
    .case__heading h2{
        font-size: 1.5rem;
    }
    .case__heading p , .vls{
        font-size: 0.8rem;
    }
}
@media screen and (max-width:520px) {    
    .case__heading h2{
        font-size: 5.5vw;
    }
    .case__heading p , .vls{
        font-size: 3vw;
    }
}

/*-------------------------------------------------------OUR RESULT-------------------------------------------------------------------*/

.case__our-result{    
    margin:0 7%;
    margin-bottom: 10rem;
}
.case__our-result h5{
    color: #757575;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}
.case__our-result .bigimg{
    width: 60%;
    margin:0 auto;
    margin-bottom: 1rem;
}
.case__our-result .fl{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 20%;
    margin-bottom: 2rem;
    gap:1rem;
}
.case__our-result .fl img{
    /* border: 1px solid black;
    border-radius: 0.5rem; */
}

.case__our-result p{
    color: #14134F;
    font-size: 1rem;
    margin-bottom: 3rem;
}

/*--------------------------------------------------------------------------------------------------------------------------*/
.case__our-result .overview{
    padding: 3rem;
    display: flex;
    flex-direction: row;
    background-color: #BCBBFF;
    border-radius: 1rem;
    gap:1rem;
    margin-bottom: 3rem;
    text-align: center;
}
.case__our-result .overview .overview_contents{   
    width:25%;
}
.case__our-result .overview .overview_contents h4{   
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}
.case__our-result .overview .overview_contents p {   
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.case__our-result .ourSolutionImg{
    margin:3rem auto;
    border-radius: 1rem;
}





@media screen and (min-width:1650px) {    
    .case__our-result h5{
        font-size: 1.5rem;
        max-width: 152rem;
        margin:1vw auto;
    }
    .case__our-result p{
        font-size: 1rem;
        max-width: 152rem;
        margin:0 auto;
    }
    .case__our-result .bigimg , .case__our-result .fl{
        width:70rem;
        margin:0 auto;
    }
    .case__our-result .bigimg {
        margin-bottom: 1.2rem;
    }
    .case__our-result .overview .overview_contents h4{   
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
    }
    .case__our-result .overview .overview_contents p {   
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .case__our-result .overview {
        max-width: 152rem;
        margin:0 auto;
        margin-top: 1vw;
    }
}
@media screen and (max-width:1024px) {    
    .case__our-result h5 , .case__our-result .overview .overview_contents h4{ 
        /* font-size: 2.2vw; */
        font-size: 1.2rem;
    }
    .case__our-result p { 
        /* font-size: 1.7vw; */
        font-size: 1.7vw;
    }
    .case__our-result .overview .overview_contents p{
        font-size: 1.8vw;
    } 
}
@media screen and (max-width:979.8px) { /*mobiles*/
    .case__our-result p { 
        /* font-size: 1.7vw; */
        font-size: 1vw;
    }
}
@media screen and (max-width:979.7px) { 
    .case__our-result p { 
        /* font-size: 1.7vw; */
        font-size: 1.7vw;
    }
}
@media screen and (max-width:825px) {    
    .case__our-result h5 , .case__our-result .overview .overview_contents h4{ 
        font-size: 1.3rem;
    }
    .case__our-result p , .case__our-result .overview .overview_contents p { 
        font-size: 1rem;
    }
}
@media screen and (max-width:700px) {    
    .case__our-result{    
        margin:0 5%;
        padding:0 3%;
    }
    .case__our-result .bigimg{
        width:80%;
    }
    .case__our-result .fl{
        grid-template-columns: 1fr;
        width:80%;
        margin:0 auto;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width:520px) {    
    
    .case__our-result h5 , .case__our-result .overview .overview_contents h4{ 
        font-size: 4vw;
    }
    .case__our-result .bigimg , .case__our-result .fl{
        width:90%;
    }
    .case__our-result p , .case__our-result .overview .overview_contents p { 
        font-size: 3vw;
    }
}






















@media screen and (max-width:760px  ) {
    /* .case__heading p{
        font-size: 0.9rem;
        width:85%;
        display: flex;
        margin:0 auto;
        justify-content: center;
    }
    .case__heading h2{
        font-size: 1.5rem;
        width:80%;
        display: flex;
        justify-content: center;
        margin:0 auto;
        margin-bottom: 1rem;
    } */
    /* .case__our-result {
        margin:0 10%;
    } */
    .case__our-result .overview{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 6vw;
        column-gap: 4vw;
    }
    .case__our-result .overview_contents {
        width:100% !important;
    }
   
    
    
}

@media screen and (max-width:425px) {
    /* .case__our-result .fl{
        flex-direction: column;
        gap:1rem;
    } */
    .case__our-result .fl img{
        width: 100%;
    }
    .case__our-result .overview{
        grid-template-columns: 1fr;
        gap:3rem !important;
    }
}









































