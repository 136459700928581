.main__header {
    width: 100vw;
    height: 48rem;
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap:6rem;
}

.main__header-left {
    margin-top: 3rem;
    padding-right: 5rem;
    /* font-size: 10vw; */
}
.main__header-right {
    margin-top: -3rem;
    position: absolute;
    width: 40vw;
    right:0;
}

.main__header-left h1 {
    margin-bottom: 1.5rem;
    font-weight: 600;
    padding-right: 4rem;

    font-size: 38px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.main__header-left p {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    /* padding-right: 12rem; */

    font-size: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 29.124px;
    color: #3D3D3E;
}
.main__header-left h1 span{
    color: #423efe;
}

.main__header-btns {
    display: flex;
    /* gap:1rem; */
    /* width:50000%; */
}
.btn {
    padding: 0.5rem 1rem;
    margin:0 1.5rem;
    font: 1rem; 
    width:50%;
    border-radius: 2rem !important;  
    display: flex; 
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.btn.SH{
    cursor: pointer;
    font-size: 1rem;
    border:1.5px solid #423efe;

}
.btn.SW{
    border:1.5px solid #423efe;
    /* background-color: #423efe; */
    border-radius: 2rem;
    color: #423efe;
    transition: color 0.3s ease;
    width: 250px;
    /* border: 1px solid red; */

}
.btn.SW:hover{
    background-color: #423efe;
    color: white;
}


.main__header-sparkle img {
    width: 44px;
    height: 44px;
    position: absolute;
    top:-4vw;
    left:-20vw;
}

.main__header-images {
    display: inline-flex;
    align-items: flex-end;
    gap:26px;
}

.main__header-images-left, .main__header-images-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width:40%;/*-----*/
}


.main__header-images-left img, .main__header-images-right img {
    border-radius: 12px;
}

.main__header-success {
    display: inline-flex;
    align-items: flex-start;
    gap: 45px;
    /* padding-right: 80px; */
    margin-top: 4.5rem;
}
.main__header-success p{
    
    padding: 0;
    margin: 0;
    width:8rem;
    color: var(--dark-background, #14134F);
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
}

.main__header-success-1st{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
}

.main__header-success-1st h4{
    color: #423EFE;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.main__header-success-1st p{
    color:  #757575;
    font-size: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (min-width:1650px) {
    .btn{
        
        width:30%;
    }
    .main__header{
        /* height:66rem; */
        height:42vw;
    }
}

@media screen and (max-width:1024px) { /*1024 and 768*/

    .main__header-left h1 {
        font-size: 3vw;
    }
    .main__header{
        height: 37rem;
    }
    .main__header-left p {
        font-size: 1.7vw;
        /* width:20vw; */
    }
    .btn.lg.SH ,.btn.lg.SW {
        font-size: 1.5vw;
        height:auto;
        width: 20vw !important;
    }
    .main__header-success {        
        gap: 1vw;       
    }
    .main__header-success p{        
        font-size: 1.9vw;       
    }
    .main__header-success-1st p{
        font-size: 1.3vw;
    }
    .main__header-sparkle img {
        
        top:-8vw;
        left:-20vw;
    }
    
}

@media screen and (max-width:825px){

    .main__header{
        height: auto;
    }
    .main__header-container {
        display: flex;
        flex-direction: column;        
    }
    .main__header-right {
        
        /* right:auto;
        position: relative; */
        display: none;
    }
    .main__header-left {
        
        left:auto;
        display:flex;
        flex-direction: column;
        padding: 0;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .main__header-left h1{
        
        padding:0;
        width:60%;
    }
    .main__header-left p{
        
        margin-bottom:1rem ;
        font-size:1.1rem;
        line-height: 1.9rem;
    }
    .main__header-success{
        
        margin-top:3rem ;
        align-items: center;
    }
    .main__header-success p{
        
       width: 30%;
    }
    .main__header-success-1st {
        
       width: 40%;
    }
    .main__header-success-1st h4{
        
       font-size: 4vw;
    }
    .main__header-success-1st p{
        
       width: 90%;
    }
    .main__header-sparkle img {
        display: none;
    }
    .main__header-btns {
        width:70%;
    }
    /* .btn.lg.SH ,.btn.lg.SW {
        
        width: 50% !important;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 1.99vw;
    } */
    .main__header-left h1{
        width:75%;
        font-size: 6vw;
    }
    
    .main__header-btns{
        width:95%;
        gap:1.1rem;
    }
    .btn{
        font-size: 1rem !important;
        margin:0;
    }
}



@media screen and (max-width:520px) {
    .main__header-left p{
        font-size: 3vw;
        line-height: 4vw;
    }
    .btn{
        font-size: 3vw !important;
    }
}




























/*Project*/

.project__container{

}
.project{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    width:100%;
}

.project-heading {
    text-align: center;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 18px;
}
.project-heading h2{
    color: #423EFE;
    font-size: 40px;
    font-weight: 600;
}
.project-heading p{
    font-size: 1.2rem;
    width: 90%;
    color: #3D3D3E; 
}

.project-card {    
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
}
.allProj {    
    margin-bottom: 6vw;
    display: flex;
    justify-content: center;
}
.project__a{
    
    border: 2px solid #423efe;
    border-radius: 3rem;
    color:#423efe;
    cursor: pointer;
    padding: 0.5rem 1.2rem;
    font-size: 1rem;
    transition: color 0.3s ease;
}
.project__a:hover{
    
    color:white;
    background-color: #423efe;
    
}
@media screen and (min-width: 1440px){
    .allProj {    
        margin-bottom: 3vw;
    }
}
@media screen and (max-width: 1300px){
    .project-card { 
        grid-template-columns: 1fr 1fr;
    }
    .card-component{
        width:35vw !important;
    }
    .card-component img{
        width:32vw !important;
    }
}

@media screen and (max-width:1024px){
    .project-heading p{
        font-size: 1.7vw;
    }
    .project__a{
        font-size:1.7vw ;
    }
}
@media screen and (max-width:825px){
    .project__container{
        margin-top: 7rem !important;
    }
    .project-heading p{
        font-size: 1.1rem;
    }
    .project__a{
        font-size:1.1rem ;
    }
    .project-card {         
        grid-template-columns: 1fr;  
        gap:3rem; 
        width:100%;     
    }
    .card-component {
        width: 70vw !important;
    }
}
@media screen and (max-width:786px){
   
    
    
    .project-heading h2{
        font-size: 6vw;
    }
    /* .project-heading p{
        font-size: 2.5vw;
    } */
    .project__a{
        padding: 0.5rem 1rem ;
        font-size: 1rem;
        /* background: red; */
        border: 1px solid #423efe;
    }
}
@media screen and (max-width:520px){
    .project__container{
        margin-top: 12vw !important;
    }
    .project-heading p{
        font-size: 3vw;
    }
    .project__a{
        font-size:3vw ;
    }
}

/*------------------------------Banner-----------------------------*/
.banner__1 img{
    width: 100%;
    position: relative;
    display: inline-block;
}
.banner__text{
    position: absolute;
    height: 28.5vw;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner__text:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.banner__text h2{
    display: inline-block;
    font-size: 3vw;
    color: rgb(255, 255, 255);
    width: 40%;
    position: relative;
    z-index: 1;
    line-height: 4vw;
    font-weight: 600;
}
.banner__1 .imgB{
    display: block;
    max-width: 100%;
    height: auto;
}
.banner__1 .imgS{
    display: none;
}
@media screen and (max-width:800px) {
    .banner__1 .imgB{
        height:38vw;
    }
    .banner__text{
        height:38vw;
    }
    .banner__text h2{
        font-size: 4.5vw;
        width:67vw;
        line-height: 7vw;
    }
}
@media screen and (max-width:520px) {
    .banner__1 .imgB{
        display: none;
    }
    .banner__1 .imgS{
        display: block;
    }
    /* .banner__text{
        position: relative;
        background-image: url("../../../public/images/banner1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    } */
}
@media screen and (max-width:520px) {
    .banner__1 img{
        height: 55vw;
    }
    .banner__text {
        height: 55vw;
    }
    .banner__text h2 {
        font-size: 6vw;
        width: 76%;
        line-height: 9vw;
    }
}
/*------------------------------Banner-----------------------------*/



/*------------------------------WWD-----------------------------*/

.project-card-wwd {    
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:24px;
    margin-bottom: 4rem;
}
.card-component__wwd p{
    font-size: 1rem;
}
.wwd__div {
    /* width:25vw; */
    height: auto;
    flex-shrink: 0;
    
}
.wwd__div p{
    padding: 0.7rem 2rem;
    background-color: #bcbbff;
    font-size: 1rem;
    border-radius: 3rem;
    color: #423efe;
    width:10.5rem;
    height: 3rem;
}
.wwd__div h3{
    margin-top: 1.5rem;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}
.SayHello{
    padding: 0.4rem 2rem !important;
    font-size: 0.9rem;
    font-family: 'Poppins';
    color: white;
    background-color: #423EFE;
    border: 2px solid #423EFE;
    border-radius: 3rem;
    transition: box-shadow 0.3s ease;
}
.SayHello:hover{
    /* background-color: #3936dc; */
    box-shadow: -10px -10px 5px rgba(0, 0, 0, 0.14);
}
@media screen and (min-width:1650px){
    .card-component__wwd{
        width:30rem !important;
    }
}
@media screen and (min-width: 1440px){
    .wdc {    
        margin-top: 3vw;
    }
}
@media screen and (max-width:1024px){
    .wwd__div a{
        font-size:small;
    }
    .wwd__div h3{
        font-size: 0.9rem;
    }
    .card-component__wwd p{
        font-size: 1.7vw;
    }
    .project-card-wwd {          
        grid-template-columns: 1fr 1fr;        
    }
    .card-component__wwd{
        width: 30vw !important;
    }
}
@media screen and (max-width:825px){
    .wwd__div a{
        font-size: 1rem;
    }
    .wwd__div h3{
        font-size: 1.5rem;
    }
    .card-component__wwd p{
        font-size: 1rem;
    }
    .card-component__wwd {
        width: fit-content !important;
    }
}
@media screen and (max-width:786px){
   
    .project-card-wwd {          
        grid-template-columns: 1fr;  
        width:100%;      
    }
    .card-component__wwd {
        /* width: fit-content !important; */
        max-width: 100rem !important;
        width:100% !important;
    }
    .wwd__div {
        width:100%;
    }
    .wwd__div h3{
        display: flex;
        margin-top: 1.5rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .wwd__div a,.wwd__div p{        
        display: flex;
        justify-content: center;
        align-items: center;
        width:fit-content;/*change it later*/
        /* font-size: 2.5vw; */
        margin:0 auto;
    }
    .wwd__div p{
        width:fit-content;
        font-size: 2.5vw;
        padding:1.5vw 3vw;
        height: fit-content;
    }
}
@media screen and (max-width:520px){
    .wwd__div a{
        font-size: 3vw;
    }
    .wwd__div h3{
        font-size: 4vw !important;
    }
    .card-component__wwd {
        /* margin:0 10%; */
    }
    .card-component__wwd svg{
        margin-bottom: 0 !important;
        width: 11% !important;
    }
    .card-component__wwd h4{
        font-size: 4.5vw !important;
        margin: 4vw 1rem !important;
    }
    .card-component__wwd p{
        font-size: 3vw !important;
    }
}

/*---------------------------------------*/


/*----------------------Testimonials----------------------*/


.asasasas1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-height: 20rem; */
    height:20rem;
    max-width: 152rem;
    margin:0 auto
  }
  
  .asasasas2 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  /*-----------SCROLLING TESTI--------*/
  .asasasas3 {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    position: absolute;
    animation-name: scrollRightToLeft;
    animation-duration: 62s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }  
  @keyframes scrollRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }  
  .asasasas4 {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    position: absolute;
    animation-name: scrollLeftToRight;
    animation-duration: 62s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }  
  @keyframes scrollLeftToRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  /*-----------SCROLLING TESTI--------*/
.testi-heading{
    margin-top: 7rem;
    text-align: center;
    display: flex;    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.testi-heading p{
    /* display: flex; */
    width:11rem;
    height: 3rem;
    padding:1rem 2rem;
    background-color: #bcbbff;
    color:#423efe;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.testi-heading h2{
    margin: 1.2rem 0 2rem 0;
    font-weight: 600;
}
.ts p{
    font-size: 0.9rem !important;
}

@media screen and (max-width:1024px){
    .ts p{
        font-size: 1.5vw !important;
    }
}
@media screen and (max-width:825px){
    .ts p{
        font-size: 0.8rem !important;
    }
}
@media screen and (max-width:786px){
    .testi-heading h2{
        font-size: 6vw;
        margin:4vw 0;
    }
    .testi-heading p{
        padding:1.5vw 3vw;
        font-size: 2.5vw;
        width: fit-content;
        height: fit-content;
    }
}
@media screen and (max-width:520px){

    .asasasas1 {
        height:12rem;
        min-height:0;
    }
    
    .ts{
        width:16rem !important;
        height:10rem !important;
    }
    .ts p{
        /* font-size: 2.5vw !important; */
        font-size: 0.67rem !important;
        padding:5vw 4vw !important;
        line-height: 0.8rem !important;
    }
    .ts .author h4{
        font-size: 0.8rem !important;
        /* font-weight:500 !important; */
    }
    .ts .author p{
        /* font-size: 0.5rem !important; */
        padding-top: 1vw !important;
        padding:0 !important;
        padding-bottom:5vw !important;
    }
}





/*------------------------Banner2--------------------*/

.banner2__text{
    height:24vw;
}
.banner2__text h2{    
    font-size: 3vw ;
}
.banner2{
    margin-top: 4rem;
}

.banner2SH{
    font-size: 1rem;
    align-items: end;
    transition: color 0.3s ease;
}

.banner2SH:hover{
    /* color:red; */
    border:1px solid white;
}
.banner2__btn{
    z-index: 1;
    margin-top: 1rem;
} 
.banner2__btn a{
    background-color: rgb(255, 255, 255);
    color: #423efe;
    top:8vw;
    position: relative;
    
} 
@media screen and (min-width:1650px){
    .banner2__text h2{    
        font-size: 2.5vw;
    }
}
@media screen and (max-width:1024px){
    .banner2SH{
        font-size: 1.7vw;
    }
    .banner2__text h2{    
        /* font-size: 2.2vw; */
    }
}
@media screen and (max-width:800px){
    .banner2__text{
        height:33vw;
    }
    .banner2__text h2{
        font-size: 4.5vw;
        width: 67vw;
        line-height: 7vw;
    }
    .banner2__btn a{
        top:12vw;
        padding:1vw 4vw !important;
    }
}
@media screen and (max-width:825px){
    .banner2SH{
        font-size: 0.9rem;
    }
    .banner2__text h2{    
        /* font-size: 1.1rem; */
    }
}
@media screen and (max-width:768px){
    .banner2SH{
        padding:0.5vw 1.2vw;
    }
}
@media screen and (max-width:520px){
    .banner2SH{
        font-size: 3vw; 
        padding:0.4vw 2vw !important;
        top:17vw !important;       
    }
    .banner2__text{
        height:25vw;



        height:45vw;/*55 -10 for center alignment*/
    }
    .banner2__text h2{    
        font-size: 6vw;
        width:76%;
        line-height: 9.5vw;
    }
}

/*------------------------Banner2--------------------*/


/*-----------------Footer-------------------------------*/
footer {
    background:#020059;
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color:rgb(255, 255, 255);
    max-width: 152rem;
    margin:0 auto;
    margin-top: 7rem;
}
.footer__container{
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap:1vw;
    margin:0 5vw;
}
.footer__container article{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0.7rem;
    
}
.footer__container article a{
    color:white;
    
}
.footer__container article p{
    margin-top: 0.5rem;
    color: white;
    /* font-size: 3rem; */
}
.footer__container article h4{
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
    color: white;
    font-weight: 600;
}
.logo p{
    font-size: 2rem;
    margin-top: -15px !important;
    top:1rem;
}
.textUnderLogo{
    width:80%;
}



.footer__copyright{
    color: white;
    text-align: center;
    padding:1rem 0;
    border-top: 2px solid rgb(255, 255, 255);
    margin-top: 5rem;
    /* max-width: 10rem; */
}

@media screen and (max-width: 1024px){
    .footer__container{
        margin:0 2vw;
        margin-left: 4vw;
        grid-template-columns: 2fr 1fr 1fr 1fr;
    }
    .textUnderLogo{
        width:80%;
    }
    
}
@media screen and (max-width: 520px){
    footer {        
        margin-top: 3rem;
    }
    .footer__container{
        display: flex;
        flex-direction: column;
        gap:3rem;
        margin: 0 1rem;
    }
    .footer__container article{
        /* align-items: center; */
        padding-left: 2rem;
        gap:0;
    }
    .footer__container article p{
        /* text-align: center; */
    }
    .footer__copyright{
        margin-top: 3rem;
    }
}



