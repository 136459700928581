*, *::before, *::after {
    margin:0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;
    --color-primary: hsl(var(var(--primary-hue),100%,50%));/*hsl => hue saturation lightness*/

    --container-width-lg: 80%;/*large devices*/
    --container-width-md: 90%;/*mobile devices*/

    --transition: all 500ms ease;
    
}

body {
    font-family: 'Poppins', sans-serif;
    color:black;
    line-height:1.7 ;
    overflow-x:hidden;
    background: #EEEEFF;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
    color: black;
}

h1{
    font-size: 3rem;
}

h2{
    font-size: 2rem;
}

img{
    display: block;
    object-fit: cover;
    width: 100%;
}

a {
    color: #39393d;
}





/* *******************************************ProjectCard Styling************************************************************* */
.card-component {
    transition: box-shadow 0.3s ease;
}
.card-component:hover{
    box-shadow: -10px -10px 5px rgba(0, 0, 0, 0.14);
    /* transform: translate(-8px, -8px);     */
}
.card-component a{
    transition: box-shadow,color 0.3s ease;
}
.card-component a:hover{
    background-color: #423efe;
    color: white !important;
    /* box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.289); */
    box-shadow: -10px -10px 5px rgba(0, 0, 0, 0.14);
}
@media screen and (min-width: 1650px){
    .card-component{
        width:30rem !important;
    }
    .card-component img{
        width:25rem !important;
    }
}
@media screen and (max-width: 1024px){
    .card-component p{
        font-size: 1.7vw  !important;
        
    }
    
}
@media screen and (max-width: 825px){
    .card-component p{
        font-size: 1.1rem  !important;        
    }
    .card-component img{
        margin-bottom:0.8rem !important;
        width:60vw !important;
    }
    .card-component h4{
        font-size: 3.5vw !important;
    }
}
@media screen and (max-width: 786px){
    .card-component h4{
        font-size: 4.2vw !important;
    }    
    
    .card-component a{
        font-size: 2.5vw !important;
        padding:1.5vw 3vw !important;
    }
}
@media screen and (max-width: 520px){
    .card-component p{
        font-size: 3vw !important;
        
    }
}



/*

@media screen and (min-width:1650px) {    
}
@media screen and (max-width:1024px) {    
}
@media screen and (max-width:825px) {    
}
@media screen and (max-width:520px) {    
}

*/