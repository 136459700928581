.about__heading{
    margin:0 10%;
    margin-top: 6.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;    
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.about__heading p{
    font-family: 'Poppins';
    color: #423EFE;
    text-align: center;
    font-size: 1.5rem;
}
.about__heading h4{
    font-family: 'Poppins';
    text-align: center;
    margin:1rem 0;
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 500;
    width:75%;
}
@media screen and (max-width:1024px) {
    .about__heading{
        padding: 0;
    }
    .about__heading p{
        font-size: 2vw;
    }   
    .about__heading h4{
        font-size: 2vw;
        font-size: 1.9vw;
        width:75%;
        line-height: 4vw;
    } 
}
@media screen and (max-width:825px) {
    .about__heading p{
        font-size: 1rem;
    }  
    .about__heading h4{
        font-size: 0.9rem;
        width:85%;
    }  
}
@media screen and (max-width:520px) {
    .about__heading p{
        font-size: 4vw;
    } 
    .about__heading h4{
        font-size: 3vw;
        margin:2vw 0;
    }   
}
/*-----------------------------------OverlayImageBAnner-------------*/
.oib {
    margin-top:2rem;
}
.oib .bimg{
    /* background-image: url('../../../public/images/oibImg1.png');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height:10rem; */
}
.oib .IMG1{
    width:100%;
    position: relative;
    display: inline-block;
    /* height:25vw; */
    height:23vw;
}
.oib .IMG2{
    display: none;
}
.oib__text {
    position:absolute;
    /* height:35vw; */
    height:23vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:100%;
}
.oib__text::before{
    content:'';
    display:inline-block;
    height:100%;
    vertical-align: middle;
}
.oib__text h2{
    font-size: 2.5vw;
    font-weight: 500;
    color:white;
    width:80%;
    z-index: 2;
}
.oib__overlay{
    /* height:35vw; */
    height:23vw;
    width:100%;
    position:absolute;
    background: #1E1BC999;
    z-index: 1;
}
@media screen and (max-width:1030px) {
    .oib .IMG1{
        display: none;
    }
    .oib .IMG2{
        width:100%;
        position: relative;
        display: inline-block;
        height:35vw;
    }
    .oib__overlay{
        height:35vw;
    }
    .oib__text {
        height:35vw;
    }
}
@media screen and (max-width:768px) {
    .oib__text h2{
        font-size: 3.42vw;
    }    
}
/*-----------------------------------OverlayImageBAnner--------------------------------------*/

/*---------------------------ZigZagCard------------------------------*/
.zzc_container{
    margin:5rem 15%;
    margin:5rem 9%;
    margin:5rem 7vw;
    display: flex;
    gap:4rem;
    flex-direction: column;
}

.zzc{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap:4vw;
    gap:8vw;
}
.zzc_contents{
    /* display: flex;
    flex-direction: column; */
    /* width:150%; */
    padding:2% 0;
}
.zzc img{    
    padding: 2%;
    /* margin:auto 0 */
}
.zzc_contents h2{
    margin: 1rem 0;
    width: 70%;
    font-weight: 500;
    line-height: normal;
    font-size: 1.5rem;
}
.zzc_contents p{
    margin: 0.5rem 0;
    font-size: 1rem;
}
.zzc__p{
    color:rgb(79,76,72);
}
.zzc_sub{
    margin:0 !important;
    /* padding: 0.5rem 1rem; */
    padding: 0.3rem 0.8rem;
    background: #bcbbff;
    color: #423efe;
    border-radius: 3rem;  
    width:fit-content ;
    /* max-width: 9rem; */
    text-align: center;
    /* font-size: 1rem; */
}

@media screen and (min-width:1650px) {
    .zzc_container{
        width:80rem ;
        margin:7rem auto;
    }
    .zzc{
        gap:7rem;
    }
}
/*DEEE*/
@media screen and (max-width:1200px) {
    .zzc{
        gap:5vw;
    }
    .zzc_container{
        margin:5rem 6.5%;
    }
}
/* @media screen and (max-width:1150px) {
    .zzc{
        grid-template-columns: 1fr 1fr;
    }
} */
/*
@media screen and (max-width:1273px) {
    .zzc_container{
        margin:5rem 13%;
    }
}
@media screen and (max-width:1240px) {
    .zzc_container{
        margin:5rem 10%;
    }
}
@media screen and (max-width:1105px) {
    .zzc_container{
        margin:5rem 8%;
    }
}
@media screen and (max-width:1051px) {
    .zzc_container{
        margin:5rem 7%;
    }
}
*/
@media screen and (max-width:1024px) {
    .zzc_contents p{
        font-size: 1.4vw;
    }    
    .zzc_contents h2{
        font-size: 2.2vw;
    }
    .zzc_container{
        margin: 5rem 10%;
        margin: 5rem 6%;
    }
    .zzc_sub{
        width: 12vw;
    }
}
@media screen and (max-width:700px) {
    .zzc_contents p{
        font-size: 0.9rem;
    }    
    .zzc_contents h2{
        font-size: 1.5rem;
        width:100%;
    }
    .zzc{
        grid-template-columns: 1fr !important;
        width: 65%;
        margin:0 auto;
    }
    .zzc img{
        order:0 !important;
    }
    .zzc_sub{
        width: 22vw;
    }
}
@media screen and (max-width:520px) {
    .zzc_container{
        margin:3rem 7vw;
    }
    .zzc_contents p{
        font-size: 3vw;
    }    
    .zzc_contents h2{
        font-size:4vw;
    }
    .zzc{
        width:90%;
    }
    .zzc_sub{
        width: 26vw;
    }
}
/*---------------------------Heading------------------------------*/
.heading_div{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.heading_div p{
    padding:0.5rem 1rem;
    font-size: 1rem;
    background-color: #bcbbff;
    color: #423efe;
    border-radius: 3rem;
    margin-bottom: 1.5rem;
}
.heading_div h2{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 5vw;
}

@media screen and (min-width:1650px) {
    .heading_div h2{
        margin-bottom: 5rem;
    }
}
@media screen and (max-width:1024px) {
    .heading_div p{
        font-size: 1.7vw;
    }
    .heading_div h2{
        font-size: 2.2vw;
    }
}
@media screen and (max-width:825px) {
    .heading_div p{
        font-size: 0.9rem;
    }
    .heading_div h2{
        font-size: 1.5rem;
    }
}
@media screen and (max-width:520px) {
    .heading_div p{
        font-size: 3vw;
    }
    .heading_div h2{
        font-size: 4vw;
    }
}

/*----------------------------WpCards--------------------------------*/
.wpCards__container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin:1rem 7vw;
    gap:1.5vw;
    margin-bottom: 8vw;
}
.wpCard_container{
    background-color: white;
    padding-bottom: 6vw;
    border-radius: 1.2rem;
}
.wpCard_container .index{
    background-color: #bcbbff;
    width:3rem;
    height:3rem;
    /* width:3.5vw;
    height: 3.5vw; */
    text-align: center;
    color: #423efe;
    margin: 0 auto;
    position: relative;
    top:-2vw;
    border-radius: 2rem;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wpCard_container .heading{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
    font-size: 1.5rem;
    font-weight:500;
}
.wpCard_container .txt{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    padding: 0 1.5rem;
    padding-top:1.5vw;
    color:rgb(79,76,72);
}

@media screen and (min-width:1650px) {
    .wpCard_container .index{
        font-size: 1.5rem;
        width:3rem;
        height: 3rem;
        top:-1.5rem;
    }
    .wpCard_container .heading{
        font-size: 1.5rem;
        padding-top: 1rem;
    }
    .wpCard_container .txt{
        font-size: 1rem;
        padding-top: 2rem;
    }
    .wpCard_container{
        width:100%;  
        padding-bottom: 5rem;      
    }
    .wpCards__container{
        width:85rem;
        margin:1rem auto;
        gap:2rem;
        margin-bottom: 10rem;
    }
}
@media screen and (max-width:1024px) {
    .wpCard_container .index{
        font-size: 1.7vw;
        width:3.5vw;
        height: 3.5vw;
    }
    .wpCard_container .heading{
        font-size: 2.2vw;
    }
    .wpCard_container .txt{
        font-size: 1.2vw;
    }
}
@media screen and (max-width:825px) {
    .wpCards__container{
        grid-template-columns: 1fr 1fr;
        column-gap:0;
        row-gap: 2rem;
    }
    .wpCard_container{
        width:85%;   
        margin: 0 auto;     
    }
    .wpCard_container .index{
        font-size: 1.3rem;
        width:3rem;
        height:3rem;
        top:-1.5rem
    }
    .wpCard_container .heading{
        font-size: 1.5rem;
    }
    .wpCard_container .txt{
        font-size: 1rem;
    }
}
@media screen and (max-width:620px) {
    .wpCards__container{
        grid-template-columns: 1fr;
    }
    .wpCards_container{
        padding-bottom: 2rem;
        width:80%;
    }
}
@media screen and (max-width:520px) {
    
    .wpCard_container .index{
        font-size: 3vw;
        width:2rem;
        height:2rem;
        top:-1rem;
    }
    .wpCard_container .heading{
        font-size: 4vw;        
    }
    .wpCard_container .txt{
        font-size: 3vw;
        padding-left: 2rem;
        padding-right: 2rem;        
    }
}

/*-------------------team-----------------*/
.teamCards__container{
    /* display: grid; */
    display: flex;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2vw;
    margin:0 auto;
    width:75%;
    margin-bottom: 5rem;
}

.team__container {
    padding:1rem;
    background-color: white;
    border-radius: 0.5rem;  
    transition: box-shadow 0.3s ease;  
}
.team__container:hover {
    box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.289);
    /* transform: translate(-8px, -8px); */
}
.team__container img{
    height: 400px;
    width: 400px;
    border-radius: 0.5rem;
    /* margin-bottom: 2vw; */
    margin-bottom: 1rem;
}
.team__container .name, .team__container .pos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0.5vw;
}

.team__container .name p{
    font-size: 1.2rem;
    font-weight: 600;
}
.team__container .pos p{
    color: gray;
    font-size: 0.9rem;
}
.team__container .name a , .team__container .pos a{
    background-color: #423efe;
    display: flex;
    width:2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
}
.team__container .name a svg{
    width:1.3rem;
    height:1.3rem;
}
.team__container .pos a svg{
    width:1.3rem;
    height:1.3rem;
}
.team__container .name a:hover , .team__container .pos a:hover{
    background-color: #007BB6;
}


/**************************************************/
.team__container .all{
    display: flex;
    justify-content: space-between;
}
.team__container .all .np{
    display: flex;
    flex-direction: column;
}
.team__container .all .np .n{/*Name*/
    font-size: 1.2rem;
    font-weight: 600;
}
.team__container .all .np .p{/*Position*/
    font-size: 0.9rem;
    color: rgb(79,76,72);
}
.team__container .all .logos{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}
.team__container .all .logos a{
    background-color: #423efe;
    width:1.8rem;
    height: 1.8rem;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
}
.team__container .all .logos a svg{
    width:1.3rem;
    height:1.3rem;
}
.team__container .all .logos a:hover{
    background-color: #007BB6;
}


@media screen and (min-width:1650px) {
    .teamCards__container{
        width:80rem;
        margin:0 auto;
    }
} 
@media screen and (max-width:1024px) { 
    .team__container .all .np .n{
        font-size: 2vw;
    }
    .team__container .all .np .p{
        font-size: 1.5vw;
    }
    .team__container .all .logos a{
        width:1.5rem;
        height:1.5rem;
    }    
    .team__container .all .logos a svg{
        width:1rem;
        height:1rem;
    }
    .teamCards__container{
        flex-direction: column;
    }
    .teamCards__container img{
        /* flex-direction: column; */
        width: 100%;
        /* height: 200px; */
    }
} 
@media screen and (max-width:825px) {
    .teamCards__container{
        grid-template-columns: 1fr 1fr;
        width:70%;
    }
    .team__container .all .np .n{
        font-size: 1rem;
    }
    .team__container .all .np .p{
        font-size: 0.8rem;
    }
}

@media screen and (max-width:620px) {
    .teamCards__container{
        grid-template-columns: 1fr;
        width:55%;
    }
    .teamCards__container img{
        /* flex-direction: column; */
        width: 100%;
        height: 200px;
    }
}
@media screen and (max-width:520px) {
    
    .team__container .all .np .n{
        font-size: 4vw;
    }
    .team__container .all .np .p{
        font-size: 3vw;
    }
    .team__container .all .logos a{
        width:1.5rem;
        height:1.5rem;
        width:6vw;
        height:6vw;
    }
    .team__container .all .logos a svg{
        width:4.5vw;
        height:4.5vw;
    }
}

