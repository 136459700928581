.contact__container{
    /* margin: 0 10%; */
    margin-top: 5.4rem;
}
.contact__heading{
    display: flex;
    justify-content: center;
}
.contact__heading h3{
    color: #212121;
    text-align: center;
    margin-top: 5rem;
    font-size: 2rem;
    font-weight: 500;
    line-height: normal;
    width:30%;
}



@media screen and (min-width:1650px) {   
    .contact__heading h3{
        font-size: 2rem;
    } 
}
@media screen and (max-width:1024px) {  
    .contact__heading h3{
        font-size: 2.7vw ;
    }  
}
@media screen and (max-width:825px) {    
    .contact__heading h3{
        font-size: 2rem;
        width: 65%;
    }
}
@media screen and (max-width:520px) {    
    .contact__heading h3{
        font-size: 5vw;
        width:55%;
        margin-top: 3rem;
    }
}

.contact__btn {
    margin:2rem;
    display: flex;
    justify-content: center;
}
.contact__btn a{
    background-color: #1CAE4E;
    padding:0.5rem 1.5rem;
    color: #F3F5FF;   
    border-radius: 3rem;
    font-size: 1rem;
    transition: box-shadow 0.3s ease;  
}
.contact__btn a:hover{
    box-shadow: -10px -10px 5px rgba(0, 0, 0, 0.14);
    background-color: darkgreen;
}

@media screen and (min-width:1650px) {    
    .contact__btn a{
        font-size:1.3rem;
    }
}
@media screen and (max-width:1024px) { 
    .contact__btn a{
        font-size: 1.7vw;
    }   
}
@media screen and (max-width:825px) {    
    .contact__btn a{
        font-size: 1rem;
    } 
}
@media screen and (max-width:520px) {    
    .contact__btn a{
        font-size: 3vw;
    } 
}

/*-------------------------------Card1----------------------------------*/
.contact__cards{
    display: flex;
    flex-direction: row;
    gap:2rem;
    justify-content: center;
    margin:0 5%;
}
.card1__container{
    background-color: white;
    padding:3rem;
    border-radius: 1rem;
    width:100%;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
}
.card1__container h3{
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
.card1__container p{
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1.2rem;
    color: rgb(79,76,72);
}
.card1__container a{
    margin:0 auto;
    border: 1px solid #423EFE;
    color: #423EFE;
    border-radius: 3rem;
    padding:0.4rem 1.3rem;
    display: flex;
    width: fit-content;
    transition: box-shadow 0.3s ease;
}
.card1__container a:hover{
    background: #423EFE;
    box-shadow: -10px -10px 5px rgba(0, 0, 0, 0.14);
    color: white;    
}

@media screen and (min-width:1650px) {    
    .contact__cards{
        margin:4rem auto;
    }
    .card1__container{
        width:28rem;
    }
}
@media screen and (max-width:1024px) {    
    .card1__container h3{
        font-size: 2.2vw;
    }
    .card1__container p , .card1__container a{
        font-size: 1.7vw;
    }
}
@media screen and (max-width:825px) {  
    .card1__container{
        padding:2rem 1rem;
    }  
    .card1__container h3{
        font-size: 1.35rem;
    }
    .card1__container p .card1__container a{
        font-size: 0.9rem;
    }
}
@media screen and (max-width:700px) {    
    .contact__cards{
        flex-direction: column;
        width:50%;
        margin:4rem auto;
    }
    .contact__cards {
        gap:2rem;
    }
    .card1__container{
        margin-bottom: 0;
    }
}
@media screen and (max-width:520px) {    
    .contact__cards{
        width:70%;
        margin:3rem auto;
    }
    .card1__container h3{
        font-size: 4vw;
    }
    .card1__container p , .card1__container a{
        font-size: 3vw;
    }
}

/*------------------------------get in touch -------------------------------------*/
.contact__get_in_touch{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin:0 20%;


    margin:0 6%;
    gap:7rem;
}
.contact__get_in_touch img{
    /* width:45vw;
    margin: 0 auto; */
    width:90%;
    margin:auto;
    border-radius: 0.8rem;
}
.git__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 3rem; */
    /* width: 50%; */
    /* text-align: center; */
}
.git__content h4{
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
.git__content h3{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
.git__content p{
    margin-bottom: 1rem;
}



@media screen and (min-width:1650px) {   
    .contact__get_in_touch{
        /* grid-template-columns: 1fr; */
        margin:0 auto;
        /* margin-top: 5rem; */
        /* gap:1rem; */
        width:50rem;
    } 
    .git__content h4{
        font-size: 1.5rem;
    }
    .git__content h3{
        font-size: 1.5rem;
    }
    .git__content p{
        font-size: 1rem;
    }
}
@media screen and (max-width:1024px) {  
    .contact__get_in_touch{
        margin:0 15%;

        margin:0 8%;
        gap:0;
    } 
    .contact__get_in_touch img{
        width:100%;
    } 
    .git__content h4{
        font-size: 2vw;
    }
    .git__content h3{
        font-size: 2vw;
    }
    .git__content p{
        font-size: 1.7vw;
    }
}
@media screen and (max-width:700px) {    
    .contact__get_in_touch{
        margin:0 10%;
    } 
    .git__content h4{
        font-size: 1.5rem;
    }
    .git__content h3{
        font-size: 1.5rem;
    }
    .git__content p{
        font-size: 1rem;
    }
}
@media screen and (max-width:700px) {
    .contact__get_in_touch{
        grid-template-columns: 1fr;
        margin:0 25%;
        margin-top: 5rem;
        gap:1rem;
    }
    .git__content{
        /* text-align: center; */
    }
}
@media screen and (max-width:520px) {    
    .contact__get_in_touch{
        margin:0 15%;
    } 
    .git__content h4{
        font-size: 4vw;
    }
    .git__content h3{
        font-size: 4vw;
    }
    .git__content p{
        font-size: 3vw;
    }
}




/* @media screen and (max-width:1024px) {
    .git__content h4{
        font-size: 2vw;
    }
    .git__content h3{
        font-size: 2.5vw;
    }
    .git__content p{
        font-size: 0.8rem;
    }



}
@media screen and (max-width:650px) {
   
    
    
     
    .contact__get_in_touch{
        margin:0 10%;
        margin-top: 2rem;
        flex-direction: column-reverse;
    }
    .contact__get_in_touch img{
        width:55vw;
    }
    .git__content{
        width:100%;
        margin-top: 2rem;
    }
    .git__content h4{
        font-size: 1.4rem;
    }
    .git__content h3{
        font-size: 1.4rem;
    }
    .git__content p{
        font-size: 0.9rem;
    }
}
 */

































