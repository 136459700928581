nav {
    height: 5rem;
    width: 100vw;
    background: #EEEEFF;
    /* background: red; */
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.nav__toggle-btn{
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 9rem;
}

.nav__links {
    display: flex;
    gap:3rem;
    align-items: center;
}

.nav__links a:hover {
    color: #6360FE;
}
.nav__links a {
    transition: var(--transition);
    transition: color 0.3s ease;
    cursor: pointer;
}

.active-nav {
    position: relative;
}

.active-nav::after {
    content: "";
    display: block;
    width:2.2rem;
    height:0.2rem;
    background: #6360FE;
    position: absolute;
    left: calc(50% -0.6rem);    
    margin-top: 0.3rem;    
}

.SH {
    border:2px solid #5350fe; 
    color:#5350fe;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    /* transition: none !important; */
    transition: color 0.3s ease;
}
.SH:hover{
    background-color: #423efe;
    color: white !important;
}




/*medium and small screens*/
@media screen and (max-width:1024px) { /*1024 and below*/
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav__toggle-btn svg{
        color:#6360FE;
    }
    .nav__links {
        position: absolute;
        top: 100%;
        right:0;
        flex-direction: column;
        gap:0;
    }

    .active-nav , .active-nav::after{
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        /* box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4); */

    }

    .nav__links li a {
        background-color: #423efe;
        color: rgb(248, 231, 200);
        height:100%;
        width:100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .SH{
    }
    .say_hello{
        padding:0 !important;
        padding-left: 3rem !important;
    }
    .a{
        /* border: 1px solid red; */
        padding: 0.5rem .8rem !important;
        border-radius: .5rem !important;
    }
    .show__nav {
        display : flex;
    }
    .hide__nav {
        display : none;
    }
    .SH {
        border-radius: 0;
        font-size: 1rem;
    }
}
